import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'Notepad',
	i18n: require('./i18n').default,
	components: {
		'notepad-svg': require('./svg.vue').default,
		'vue-filter': require('./Filter/index.vue').default,
		'vue-add-label': require('./AddLabel/index.vue').default,
		'vue-add-new-label': require('./AddNewLabel/index.vue').default,
	},
	metaInfo() {
		return {
			title: `AUTO.RIA™ — ${this.langId === 2 ? 'Автобазар №1. Купить и продать авто легко как никогда' : 'Автобазар №1. Купити і продати авто легко як ніколи'}`,
			meta: [
				{name: 'description', content: this.langId === 2 ? 'рус текст' : 'укр текст'},
				{name: 'keywords', content: ''},
				{property: 'og:title', content: ''},
				{property: 'og:description', content: ''},
				{name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
			],
			link: [
				{rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/'},
				{rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/'},
				{rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/'},
				{rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/'},
				{rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/'},
			]
		};
	},
};
