export default {
	messages: {
		uk: {
			'Збережені пошуки': 'Збережені пошуки',
		},
		ru: {
			'Збережені пошуки': 'Сохраненные поиски',
		},
	}
};
